<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-melrose-customers' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Customers</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>
            {{ customer.full_name }}
            <v-btn
              v-if="customer.seller"
              small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
            >
              Seller
            </v-btn>
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters> </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Basic Details</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.customerDialog.open(customer)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Customer's Information</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-subtitle class="grey lighten-5"
                      >Customer's Information</v-card-subtitle
                    >
                    <v-divider></v-divider>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <th>Full Name</th>
                          <td>{{ customer.full_name }}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{{ customer.email }}</td>
                        </tr>
                        <tr>
                          <th>Phone</th>
                          <td>{{ customer.phone }}</td>
                        </tr>
                        <tr>
                          <th>Buyers Position</th>
                          <td>
                            {{ customer.buyers_position }}
                          </td>
                        </tr>
                        <tr>
                          <th>Is Actively Looking</th>
                          <td>
                            {{ customer.is_actively_looking ? "Yes" : "No" }}
                          </td>
                        </tr>
                        <tr>
                          <th>Source</th>
                          <td>
                            {{ customer.source }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-subtitle class="grey lighten-5"
                      >Address</v-card-subtitle
                    >
                    <v-divider></v-divider>
                    <v-simple-table v-if="customer.current_address">
                      <tbody>
                        <tr>
                          <th>Line 1</th>
                          <td>{{ customer.current_address.address1 }}</td>
                        </tr>
                        <tr>
                          <th>Line 2</th>
                          <td>{{ customer.current_address.address2 }}</td>
                        </tr>
                        <tr>
                          <th>City</th>
                          <td>{{ customer.current_address.city }}</td>
                        </tr>
                        <tr>
                          <th>County</th>
                          <td>{{ customer.current_address.county }}</td>
                        </tr>
                        <tr>
                          <th>Postcode</th>
                          <td>{{ customer.current_address.postcode }}</td>
                        </tr>
                        <tr>
                          <th>Country</th>
                          <td>{{ customer.current_address.country }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3">Viewings</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="viewingTableHeaders"
              :items="customer.viewings"
              show-expand
              no-data-text="No Viewings found"
            >
              <template v-slot:item.property="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.property.id },
                  }"
                  v-html="item.property.address.short"
                ></router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.viewingDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Performed By?</th>
                        <td v-html="item.viewings_performed_by"></td>
                      </tr>
                      <tr>
                        <th>Follow Up Date</th>
                        <td v-html="item.formatted_dates.follow_up_date"></td>
                      </tr>
                      <tr>
                        <th>Feedback Received?</th>
                        <td>
                          <v-chip
                            label
                            small
                            color="success"
                            v-if="item.feedback_received"
                            >Yes</v-chip
                          >
                          <v-chip label small color="error" v-else>No</v-chip>
                        </td>
                      </tr>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3"
              >Valuation Requests</v-card-title
            >
            <v-divider></v-divider>
            <v-data-table
              :headers="valuationRequestTableHeaders"
              :items="customer.valuation_requests"
              show-expand
              no-data-text="No Valuation Requests found"
            >
              <template v-slot:item.property="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.property.id },
                  }"
                  v-html="item.property.address.short"
                ></router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.valuationRequestDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Description</th>
                        <td v-html="item.description" class="pt-4 pb-4"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-3">Offers</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="offerTableHeaders"
              :items="customer.offers"
              show-expand
              no-data-text="No Offers found"
            >
              <template v-slot:item.property="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.property.id },
                  }"
                  v-html="item.property.address.short"
                ></router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.offerDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                      <tr>
                        <th>Solicitor</th>
                        <td class="pt-4 pb-4">
                          <div>{{ item.solicitor.name }}</div>
                          <div>
                            <strong>Phone:</strong> {{ item.solicitor.phone }}
                          </div>
                          <div>
                            <strong>Email:</strong> {{ item.solicitor.email }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3">Properties</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row v-if="customer.properties.length > 0">
                <v-col
                  cols="6"
                  v-for="property in customer.properties"
                  :key="property.id"
                >
                  <v-card>
                    <v-img
                      v-if="property.main_image"
                      :src="property.main_image.url"
                      height="200px"
                      contain
                    ></v-img>
                    <v-card-title>{{ property.address.city }}</v-card-title>
                    <v-card-subtitle>{{
                      property.address.short
                    }}</v-card-subtitle>
                    <v-divider></v-divider>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <th>Status</th>
                          <td>{{ property.status }}</td>
                        </tr>
                        <tr>
                          <th>Price</th>
                          <td>
                            {{ property.current_price.type }} £{{
                              property.current_price.price
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        :to="{
                          name: 'module-melrose-properties-individual',
                          params: { propertyId: property.id },
                        }"
                        small
                        block
                        depressed
                        color="green lighten-4 green--text"
                        ><v-icon left>mdi-eye</v-icon>View</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <div v-else class="text-center text--disabled">
                No Properties found
              </div>
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3">Enquiries</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="enquiriesTableHeaders"
              :items="customer.enquiries"
              show-expand
              no-data-text="No Enquiries found"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.noteOfInterestDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item> </template>
            </v-data-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-3"
              >Notes of Interest</v-card-title
            >
            <v-divider></v-divider>
            <v-data-table
              :headers="notesOfInterestTableHeaders"
              :items="customer.notes_of_interest"
              show-expand
              no-data-text="No Notes of Interest found"
            >
              <template v-slot:item.property="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.property.id },
                  }"
                  v-html="item.property.address.short"
                ></router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.noteOfInterestDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                      <tr>
                        <th>Solicitor</th>
                        <td class="pt-4 pb-4">
                          <div>{{ item.solicitor.name }}</div>
                          <div>
                            <strong>Phone:</strong> {{ item.solicitor.phone }}
                          </div>
                          <div>
                            <strong>Email:</strong> {{ item.solicitor.email }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <customer-dialog ref="customerDialog" />
    <address-dialog ref="addressDialog" />
    <viewing-dialog ref="viewingDialog" :editingFromCustomer="true" />
    <valuation-request-dialog
      ref="valuationRequestDialog"
      :editingFromCustomer="true"
    />
    <offer-dialog ref="offerDialog" :editingFromCustomer="true" />
    <note-of-interest-dialog
      ref="noteOfInterestDialog"
      :editingFromCustomer="true"
    />
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog.vue";
import AddressDialog from "../properties/components/AddressDialog.vue";
import ViewingDialog from "../properties/viewings/components/ViewingDialog.vue";
import ValuationRequestDialog from "../properties/valuation-requests/components/ValuationRequestDialog.vue";
import OfferDialog from "../properties/offers/components/OfferDialog.vue";
import NoteOfInterestDialog from "../properties/notes-of-interest/components/NoteOfInterestDialog.vue";

export default {
  components: {
    CustomerDialog,
    AddressDialog,
    ViewingDialog,
    ValuationRequestDialog,
    OfferDialog,
    NoteOfInterestDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          to: { name: "module-melrose-customers" },
          exact: true,
        },
      ],
      viewingTableHeaders: [
        { text: "Viewing Date", value: "formatted_dates.viewing_date" },
        { text: "Property", value: "property" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      valuationRequestTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      offerTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      notesOfInterestTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      enquiriesTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.getters["melrose/customersStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.customer.full_name,
      disabled: true,
    });
  },
};
</script>
