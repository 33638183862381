<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Valuation Request
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form
          @submit.prevent="save"
          method="post"
          id="valuation-request-form"
        >
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :disabled="editingFromCustomer"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-select>
          <v-select
            label="Property"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="address.short"
            outlined
            background-color="white"
            :disabled="editingFromProperty"
            :error="errors.hasOwnProperty('property_id')"
            :error-messages="errors['property_id']"
          ></v-select>
          <v-text-field
            label="Date *"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
          <v-textarea
            label="Description"
            v-model="fields.description"
            rows="5"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="valuation-request-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingFromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
    editingFromCustomer: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      valuation_request: {},
      fields: {
        customer_id: null,
        date: null,
        description: null,
        status: null,
        propery_id: null,
      },
      statuses: [
        "Submitted",
        "Client Notified",
        "Rejected",
        "Accepted",
        "Back With Customer",
      ],
      errors: {},
    };
  },

  methods: {
    open(valuation_request = null) {
      if (valuation_request !== null) {
        this.valuation_request = valuation_request;
        this.isEditing = true;
        this.fields.customer_id = valuation_request.customer.id;
        this.fields.date = valuation_request.date;
        this.fields.description = valuation_request.description;
        this.fields.status = valuation_request.status;
        this.fields.property_id = valuation_request.property.id;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.valuationRequestId = this.valuation_request.id;
      }

      this.$store
        .dispatch("melrose/propertiesStore/saveValuationRequest", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.valuation_request = {};
      this.fields = {
        customer_id: null,
        date: null,
        description: null,
        status: null,
        property_id: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },
  },
};
</script>
